import React from 'react'
import { default as SubHeader } from 'components/header'
import Layout from 'components/Layout'
import 'style/term.scss'

const TnC = () => {
  return (
    <Layout>
      <SubHeader>
        <p className="header-title">Terms and Condition</p>
        <p className="header-description">Last Updated: 1st October 2019</p>
      </SubHeader>
      <div className="term-content">
        <p><strong>1. INTRODUCTION</strong></p>
        <p>1.1 Welcome to the JiMATBIZ platform (the “Site”). Please read the following Terms of Service carefully before using this Site or opening a JiMAT account (“Account”) so that you are aware of your legal rights and obligations with respect to ROBU Technologies Sdn. Bhd. and its affiliates and subsidiaries (individually and collectively, “JiMAT”, “we”, “us” or “our”). The “Services” we provide or make available include (a) the Site, (b) the services provided by the Site and by JiMAT client software made available through the Site, and © all information, linked pages, features, data, text, images, photographs, graphics, music, sounds, video (including live streams), messages, tags, content, programming, software, application services (including, without limitation, any mobile application services) or other materials made available through the Site or its related services (“Content”). Any new features added to or augmenting the Services are also subject to these Terms of Service. These Terms of Service govern your use of Services provided by JiMAT.</p>
        <p>1.2 The Services include an online platform service that provides a place and opportunity for the sale of goods between the buyer (“Buyer”) and the seller (“Seller”) (collectively “you”, “Users” or “Parties”). The actual contract for sale is directly between Buyer and Seller and JiMAT is not a party to that or any other contract between Buyer and Seller and accepts no obligations in connection with any such contract. Parties to such transaction will be entirely responsible for the sales contract between them, the listing of goods, warranty of purchase and the like. JiMAT is not involved in the transaction between Users. JiMAT may or may not pre-screen Users or the Content or information provided by Users. JiMAT reserves the right to remove any Content or information posted by you on the Site in accordance to Section 6.4 herein. JiMAT cannot ensure that Users will actually complete a transaction.</p>
        <p>1.3  Before becoming a User of the Site, you must read and accept all of the terms and conditions in, and linked to, these Terms of Service and you must consent to the processing of your personal data as described in the Privacy Policy linked hereto.</p>
        <p>1.4 JiMAT reserves the right to change, modify, suspend or discontinue all or any part of this Site or the Services at any time or upon notice as required by local laws. JiMAT may release certain Services or their features in a beta version, which may not work correctly or in the same way the final version may work, and we shall not be held liable in such instances. JiMAT may also impose limits on certain features or restrict your access to parts of, or the entire, Site or Services in its sole discretion and without notice or liability.</p>
        <p>1.5 JiMAT reserves the right to refuse to provide you access to the Site or Services or to allow you to open an Account for any reason.</p>
        <p>BY USING JiMAT SERVICES OR OPENING AN ACCOUNT, YOU GIVE YOUR IRREVOCABLE ACCEPTANCE OF AND CONSENT TO THE TERMS OF THIS AGREEMENT, INCLUDING THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR LINKED HERETO.</p>
        <p>IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE OUR SERVICES OR ACCESS THE SITE. IF YOU ARE UNDER THE AGE OF 18 OR THE LEGAL AGE FOR GIVING CONSENT HEREUNDER PURSUANT TO THE APPLICABLE LAWS IN YOUR COUNTRY (THE “LEGAL AGE”), YOU MUST GET PERMISSION FROM A PARENT OR LEGAL GUARDIAN TO OPEN AN ACCOUNT AND THAT PARENT OR LEGAL GUARDIAN MUST AGREE TO THE TERMS OF THIS AGREEMENT. IF YOU DO NOT KNOW WHETHER YOU HAVE REACHED THE LEGAL AGE, OR DO NOT UNDERSTAND THIS SECTION, PLEASE DO NOT CREATE AN ACCOUNT UNTIL YOU HAVE ASKED YOUR PARENT OR LEGAL GUARDIAN FOR HELP. IF YOU ARE THE PARENT OR LEGAL GUARDIAN OF A MINOR WHO IS CREATING AN ACCOUNT, YOU MUST ACCEPT THE TERMS OF THIS AGREEMENT ON THE MINOR’S BEHALF AND YOU WILL BE RESPONSIBLE FOR ALL USE OF THE ACCOUNT OR COMPANY SERVICES USING SUCH ACCOUNT, WHETHER SUCH ACCOUNT IS CURRENTLY OPEN OR CREATED LATER.</p>
        <p><strong>2. PRIVACY</strong></p>
        <p>2.1 Your privacy is very important to us at JiMAT. To better protect your rights we have provided the JiMAT Privacy Policy to explain our privacy practices in detail. Please review the Privacy Policy to understand how JiMAT collects and uses the information associated with your Account and/or your use of the Services (the “User Information”). By using the Services or providing information on the Site, you:</p>
        <p>(i) consent to JiMAT’s collection, use, disclosure and/or processing of your Content, personal data and User Information as described in the Privacy Policy;</p>
        <p>(ii) agree and acknowledge that the proprietary rights of your User Information are jointly owned by you and JiMAT; and</p>
        <p>(iii) shall not, whether directly or indirectly, disclose your User Information to any third party, or otherwise allow any third party to access or use your User Information, without JiMAT’s prior written consent.</p>
        <p>2.2 Users in possession of another User’s personal data through the use of the Services (the “Receiving Party”) hereby agree that, they will (i) comply with all applicable personal data protection laws with respect to any such data; (ii) allow the User whose personal data the Receiving Party has collected (the “Disclosing Party”) to remove his or her data so collected from the Receiving Party’s database; and (iii) allow the Disclosing Party to review what information have been collected about them by the Receiving Party, in each case of (ii) and (iii) above, in compliance with and where required by applicable laws.</p>
        <p><strong>3. LIMITED LICENSE</strong></p>
        <p>3.1 JiMAT grants you a non-transferable and revocable license to access and use the Services subject to the terms and conditions of these Terms of Service. All intellectual property rights and proprietary content, whether registered or unregistered, trademarks, service marks, patent, brand names, logos and other intellectual property (“Intellectual Property”) displayed in the Site are the property of JiMAT and where applicable, third party proprietors identified in the Site. No right or licence is granted directly or indirectly to any party accessing the Site to use or reproduce any Intellectual Property, and no party accessing the Site shall claim any right, title or interest therein. By using or accessing the Services you agree to comply with the copyright, trademark, service mark, and all other applicable laws that protect the Services, the Site and its Content. You agree not to copy, distribute, republish, transmit, publicly display, publicly perform, modify, adapt, rent, sell, or create derivative works of any portion of the Services, the Site or its Content. You also may not, without our prior written consent, mirror or frame any part or whole of the contents of this Site on any other server or as part of any other website. In addition, you agree that you will not use any robot, spider or any other automatic device or manual process to monitor or copy our Content, without our prior written consent (such consent is deemed given for standard search engine technology employed by Internet search websites to direct Internet users to this website).</p>
        <p>3.2 You are welcome to link to the Site from your website, provided that your website does not imply any endorsement by or association with JiMAT. You acknowledge that JiMAT may, in its sole discretion and at any time, discontinue providing the Services, either in part or as a whole, without notice.</p>
        <p><strong>4. SOFTWARE</strong></p>
        <p>Any software provided by us to you as part of the Services is subject to the provisions of these Terms of Service. JiMAT reserves all rights to the software not expressly granted by JiMAT hereunder. Any third-party scripts or code, linked to or referenced from the Services, are licensed to you by the third parties that own such scripts or code, not by JiMAT.</p>
        <p><strong>5. ACCOUNTS AND SECURITY</strong></p>
        <p>5.1  Some functions of our Services require registration for an Account by selecting a unique user identification (“User ID”) and password, and by providing certain personal information. If you select a User ID that JiMAT, in its sole discretion, finds offensive or inappropriate, JiMAT has the right to suspend or terminate your Account. You may be able to use your Account to gain access to other products, websites or services to which we have enabled access or with which we have tied up or collaborated. JiMAT has not reviewed, and assumes no responsibility for any third party content, functionality, security, services, privacy policies, or other practices of those products, websites or services. If you do so, the terms of service for those products, websites or services, including their respective privacy policies, if different from these Terms of Service and/or our Privacy Policy, may also apply to your use of those products, websites or services.</p>
        <p>5.2 You agree to (a) keep your password confidential and use only your User ID and password when logging in, (b) ensure that you log out from your account at the end of each session on the Site, © immediately notify JiMAT of any unauthorised use of your Account, User ID and/or password, and (d) ensure that your Account information is accurate and up-to-date. You are fully responsible for all activities that occur under your User ID and Account even if such activities or uses were not committed by you. JiMAT will not be liable for any loss or damage arising from unauthorised use of your password or your failure to comply with this Section.</p>
        <p>5.3 You agree that JiMAT may for any reason, in its sole discretion and with or without notice or liability to you or any third party, immediately terminate your Account and your User ID, remove or discard from the Site any Content associated with your Account and User ID, withdraw any subsidies offered to you, cancel any transactions associated with your Account and User ID, temporarily withhold any sale proceeds or refunds, and/or take any other actions that JiMAT deems necessary. Grounds for such actions may include, but are not limited to, (a) extended periods of inactivity, (b) violation of the letter or spirit of these Terms of Service, © illegal, fraudulent, harassing, defamatory, threatening or abusive behaviour (d) having multiple user accounts, or (e) behaviour that is harmful to other Users, third parties, or the business interests of JiMAT. Use of an Account for illegal, fraudulent, harassing, defamatory, threatening or abusive purposes may be referred to law enforcement authorities without notice to you. If a legal dispute arises or law enforcement action is commenced relating to your Account or your use of the Services for any reason, JiMAT may terminate your Account immediately with or without notice.</p>
        <p>5.4  Users may terminate their Account if they notify JiMAT in writing (including via email at <a href="mailto:support@robugroup.com">support@robugroup.com</a>) of their desire to do so. Notwithstanding any such termination, Users remain responsible and liable for any incomplete transaction (whether commenced prior to or after such termination), shipment of the product, payment for the product, or the like, and Users must contact JiMAT after he or she has promptly and effectively carried out and completed all incomplete transactions according to the Terms of Service. JiMAT shall have no liability, and shall not be liable for any damages incurred due to the actions taken in accordance with this Section. Users waive any and all claims based on any such action taken by JiMAT.</p>
        <p>5.5 You may only use the Services and/or open an Account if you are located in one of our approved countries, as updated from time to time.</p>
        <p><strong>6. TERM OF USE</strong></p>
        <p>6.1 The license for use of this Site and the Services is effective until terminated. This license will terminate as set forth under these Terms of Service or if you fail to comply with any terms or conditions of these Terms of Service. In any such event, JiMAT may effect such termination with or without notice to you.</p>
        <p>6.2 You agree not to:</p>
        <p>(a) upload, post, transmit or otherwise make available any Content that is unlawful, harmful, threatening, abusive, harassing, alarming, distressing, tortuous, defamatory, vulgar, obscene, libellous, invasive of another’s privacy, hateful, or racially, ethnically or otherwise objectionable;</p>
        <p>(b) violate any laws, including without limitation any laws and regulation in relation to export and import restrictions, third party rights or our <strong>Prohibited and Restricted Items Policy</strong>;</p>
        <p>(c) use the Services to harm minors in any way;</p>
        <p>(d) use the Services or upload Content to impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity;</p>
        <p>(e) forge headers or otherwise manipulate identifiers in order to disguise the origin of any Content transmitted through the Services;</p>
        <p>(f) remove any proprietary notices from the Site;</p>
        <p>(g) cause, permit or authorize the modification, creation of derivative works, or translation of the Services without the express permission of JiMAT;</p>
        <p>(h) use the Services for the benefit of any third party or any manner not permitted by the licenses granted herein;</p>
        <p>(i) use the Services or upload Content in a manner that is fraudulent, unconscionable, false, misleading or deceptive;</p>
        <p>(j) open and/or operate multiple user accounts in connection with any conduct that violates either the letter or spirit of these Terms of Service;</p>
        <p>(k) access the JiMAT platform, open a user account, or otherwise access your user account using an emulator, simulator, bot, or other similar hardware or software;</p>
        <p>(l) manipulate the price of any item or interfere with other User’s listings;</p>
        <p>(m) take any action that may undermine the feedback or ratings systems;</p>
        <p>(n) attempt to decompile, reverse engineer, disassemble or hack the Services (or any portion thereof), or to defeat or overcome any encryption technology or security measures implemented by JiMAT with respect to the Services and/or data transmitted, processed or stored by JiMAT;</p>
        <p>(o) harvest or collect any information about or regarding other Account holders, including, without limitation, any personal data or information;</p>
        <p>§ upload, email, post, transmit or otherwise make available any Content that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);</p>
        <p>(q) upload, email, post, transmit or otherwise make available any Content that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party;</p>
        <p>® upload, email, post, transmit or otherwise make available any unsolicited or unauthorised advertising, promotional materials, “junk mail”, “spam”, “chain letters”, “pyramid schemes”, or any other unauthorised form of solicitation;</p>
        <p>(s) upload, email, post, transmit or otherwise make available any material that contains software viruses, worms, Trojan-horses or any other computer code, routines, files or programs designed to directly or indirectly interfere with, manipulate, interrupt, destroy or limit the functionality or integrity of any computer software or hardware or data or telecommunications equipment;</p>
        <p>(t) disrupt the normal flow of dialogue, cause a screen to “scroll” faster than other Users of the Services are able to type, or otherwise act in a manner that negatively affects other Users’ ability to engage in real time exchanges;</p>
        <p>(u) interfere with, manipulate or disrupt the Services or servers or networks connected to the Services or any other User’s use and enjoyment of the Services, or disobey any requirements, procedures, policies or regulations of networks connected to the Site;</p>
        <p>(v) take any action or engage in any conduct that could directly or indirectly damage, disable, overburden, or impair the Services or the servers or networks connected to the Services;</p>
        <p>(w) use the Services to intentionally or unintentionally violate any applicable local, state, national or international law, rule, code, directive, guideline, policy or regulation including, without limitation, laws and requirements (whether or not having the force of law) relating to anti-  money laundering or counter-terrorism;</p>
        <p>(x) use the Services to violate the privacy of others or to “stalk” or otherwise harass another;</p>
        <p>(y) infringe the rights of JiMAT, including any intellectual property rights and any passing off of the same thereof;</p>
        <p>(z) use the Services to collect or store personal data about other Users in connection with the prohibited conduct and activities set forth above; and/or</p>
        <p>(aa) list items which infringe upon the copyright, trademark or other intellectual property rights of third parties or use the Services in a manner which will infringe the intellectual property rights of others.</p>
        <p>6.3  You understand that all Content, whether publicly posted or privately transmitted, is the sole responsibility of the person from whom such Content originated. This means that you, and not JiMAT, are entirely responsible for all Content that you upload, post, email, transmit or otherwise make available through the Site. You understand that by using the Site, you may be exposed to Content that you may consider to be offensive, indecent or objectionable. To the maximum extent permitted by applicable law, under no circumstances will JiMAT be liable in any way for any Content, including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of, or reliance on, any Content posted, emailed, transmitted or otherwise made available on the Site.</p>
        <p>6.4  You acknowledge that JiMAT and its designees shall have the right (but not the obligation) in their sole discretion to pre-screen, refuse, delete, stop, suspend, remove or move any Content, including without limitation any Content or information posted by you, that is available on the Site. Without limiting the foregoing, JiMAT and its designees shall have the right to remove any Content (i) that violates these Terms of Service; (ii) if we receive a complaint from another User; (iii) if we receive a notice of intellectual property infringement or other legal instruction for removal; or (iv) if such Content is otherwise objectionable. We may also block delivery of a communication (including, without limitation, status updates, postings, messages and/or chats) to or from the Services as part of our effort to protect the Services or our Users, or otherwise enforce the provisions of these Terms and Conditions. You agree that you must evaluate, and bear all risks associated with, the use of any Content, including, without limitation, any reliance on the accuracy, completeness, or usefulness of such Content. In this regard, you acknowledge that you have not and, to the maximum extent permitted by applicable law, may not rely on any Content created by JiMAT or submitted to JiMAT, including, without limitation, information in JiMAT Forums and in all other parts of the Site.</p>
        <p>6.5  You acknowledge, consent to and agree that JiMAT may access, preserve and disclose your Account information and Content if required to do so by law or pursuant to an order of a court or by any governmental or regulatory authority having jurisdiction over JiMAT or in a good faith belief that such access preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce these Terms of Service; © respond to claims that any Content violates the rights of third parties; (d) respond to your requests for customer service; or (e) protect the rights, property or personal safety of JiMAT, its Users and/or the public.</p>
        <p><strong>7.  VIOLATION OF OUR TERMS OF SERVICE</strong></p>
        <p>7.1  Violations of this policy may result in a range of actions, including, without limitation, any or all of the following:</p>
        <ul>
          <li>
            <p>Listing deletion</p>
          </li>
          <li>
            <p>Limits placed on Account privileges</p>
          </li>
          <li>
            <p>Account suspension and subsequent termination</p>
          </li>
          <li>
            <p>Criminal charges</p>
          </li>
          <li>
            <p>Civil actions, including without limitation a claim for damages and/or interim or injunctive relief</p>
          </li>
        </ul>
        <p>7.2  If you believe a User on our Site is violating these Terms of Service, please contact <a href="mailto:support@robugroup.com">support@robugroup.com</a></p>
        <p><strong>8. REPORTING INTELLECTUAL PROPERTY RIGHTS INFRINGEMENT</strong></p>
        <p>8.1 The Users are independent individuals or businesses and they are not associated with JiMAT in any way. JiMAT is neither the agent nor representative of the Users and does not hold and/or own any of the merchandises listed on the Site.</p>
        <p>8.2 If you are an intellectual property right owner (“IPR Owner”) or an agent duly authorised by an IPR Owner (“IPR Agent”) and you believe that your right or your principal’s right has been infringed, please notify us in writing by email to  <a href="mailto:support@robugroup.com">support@robugroup.com</a> and provide us the documents requested below to support your claim. Do allow us time to process the information provided. JiMAT will respond to your complaint as soon as practicable.</p>
        <p>8.3 Complaints under this Section 8 must be provided in the form prescribed by JiMAT, which may be updated from time to time, and must include at least the following: (a) a physical or electronic signature of an IPR Owner or IPR Agent (collectively, “Informant”); (b) a description of the type and nature of intellectual property right that is allegedly infringed and proof of rights; © details of the listing which contains the alleged infringement; (d) sufficient information to allow JiMAT to contact the Informant, such as Informant’s physical address, telephone number and e-mail address; (e) a statement by Informant that the complaint is filed on good faith belief and that the use of the intellectual property as identified by the Informant is not authorised by the IPR Owner or the law; (f) a statement by the Informant that the information in the notification is accurate, indemnify us for any damages we may suffer as a result of the information provided by and that the Informant has the appropriate right or is authorised to act on IPR Owner’s behalf to the complaint.</p>
        <p><strong>9.  PURCHASE AND PAYMENT</strong></p>
        <p>9.1  All payments shall be made directly to Sellers until JiMAT provides a platform for online payments.</p>
        <p>9.2  JiMAT shall provide automated invoices and receipts to Buyers and/or Sellers upon required updates from Buyers and/or Sellers in the platform.</p>
        <p>9.3  JiMAT takes no responsibility and assume no liability for any loss or damages to Buyer arising from shipping information and/or payment information entered by Buyer or wrong remittance by Buyer in connection with the payment for the items purchased. We reserve the right to check whether Buyer is duly authorised to use certain payment method, and may suspend the transaction until such authorisation is confirmed or cancel the relevant transaction where such confirmation is not available.</p>
        <p><strong>10. DELIVERY</strong></p>
        <p>10.1 JiMAT’s business model currently provides delivery and/or pick up at Seller’s store/stores only. JiMAT will send Bulk Purchase Orders (BPO) to Sellers in an automated manner when JiMAT receives Retailer’s Purchase Orders (RPO).</p>
        <p>10.2 Seller must use his/her best effort to ensure that Buyer receives the purchased items within the time period specified by Seller on Seller’s listing.</p>
        <p>10.3 Users understand that Seller bears all risk attached to the delivery of the purchased item(s) and warrants that he/she has or will obtain adequate insurance coverage for the delivery of the purchased item(s). In the event where the purchased item(s) is damaged, lost or failure of delivery during the course of delivery, Users acknowledge and agree that JiMAT will not be liable for any damage, expense, cost or fees resulted therefrom and Seller and/or Buyer will reach out to the logistic service provider to resolve such dispute.</p>
        <p><strong>11.  CANCELLATION, RETURN AND REFUND</strong></p>
        <p>11.1  The acceptance of any cancellation is subject to Seller’s prerogative. Buyers are advised to contact JiMAT at <a href="mailto:support@robugroup.com">support@robugroup.com</a> for any cancellation.</p>
        <p>11.2 JiMAT reserves the right to cancel any transaction on the Site and Buyer agrees that Buyer’s sole remedy will be to receive a refund of the Buyer’s Purchase Orders paid into Seller’s Account.</p>
        <p>11.3 JiMAT does not monitor the cancellation, return and refund process for offline payment.</p>
        <p><strong>12.  SELLER’S RESPONSIBILITIES</strong></p>
        <p>12.1  Seller shall properly manage and ensure that relevant information such as the price and the details of items, inventory amount and terms and conditions for sales is updated on Seller’s listing and shall not post inaccurate or misleading information.</p>
        <p>12.2  The price of items for sale will be determined by the Seller at his/her own discretion. The price of an item shall include the entire amount to be charged to Buyer such as sales tax, value-added tax, tariffs, etc. and Seller shall not charge Buyer such amount additionally and separately.</p>
        <p>12.3 Seller agrees that JiMAT may at its discretion engage in promotional activities to induce transactions between Buyer and Seller by reducing, discounting or refunding fees, or in other ways. The final price that Buyer will pay actually will be the price that such adjustment is applied to.</p>
        <p>12.4 For the purpose of promoting the sales of the items listed by Seller, JiMAT may post such items (at adjusted price) on third-party websites (such as portal sites and price comparison sites) and other websites (domestic or foreign) operated by JiMAT.</p>
        <p>12.6 Seller acknowledges and agrees that Seller will be responsible for paying all taxes, customs and duties for the item sold and JiMAT cannot provide any legal or tax advice in this regard. As tax laws and regulations may change from time to time, Sellers are advised to seek professional advice if in doubt.</p>
        <p>12.7 Seller acknowledge and agrees that Seller’s violation of any of JiMAT’s polices will result in a range of actions as stated in Section 7.1.</p>
        <p><strong>13.  DISPUTES</strong></p>
        <p>13.1  In the event a problem arises in a transaction, the Buyer and Seller agree to communicate with each other first to attempt to resolve such dispute by mutual discussions, which JiMAT shall use reasonable commercial efforts to facilitate. If the matter cannot be resolved by mutual discussions, Users may approach the claims tribunal of their local jurisdiction to resolve any dispute arising from a transaction.</p>
        <p>13.2  Each Buyer and Seller covenants and agrees that it will not bring suit or otherwise assert any claim against JiMAT or its Affiliates (except where JiMAT or its Affiliates is the Seller of the product that the claim relates to) in relation to any transaction made on the Site or any dispute related to such transaction.</p>
        <p><strong>14. FEEDBACK</strong></p>
        <p>14.1 JiMAT welcomes information and feedback from our Users which will enable JiMAT to improve the quality of service provided. Please refer to our feedback procedure below for further information:</p>
        <p>(i)  Feedback may be made in writing through email <a href="mailto:support@robugroup.com">support@robugroup.com</a> or using the feedback form found on the App.</p>
        <p>(ii)  Anonymous feedback will not be accepted.</p>
        <p>(iii)  Users affected by the feedback should be fully informed of all facts and given the opportunity to put forward their case.</p>
        <p>(iv) Vague and defamatory feedback will not be entertained.</p>
        <p><strong>15. DISCLAIMERS</strong></p>
        <p>15.1  THE SERVICES ARE PROVIDED “AS IS” AND WITHOUT ANY WARRANTIES, CLAIMS OR REPRESENTATIONS MADE BY JiMAT OF ANY KIND EITHER EXPRESSED, IMPLIED OR STATUTORY WITH RESPECT TO THE SERVICES, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF QUALITY, PERFORMANCE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE, NOR ARE THERE ANY WARRANTIES CREATED BY COURSE OF DEALING, COURSE OF PERFORMANCE OR TRADE USAGE. WITHOUT LIMITING THE FOREGOING AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, JiMAT DOES NOT WARRANT THAT THE SERVICES, THIS SITE OR THE FUNCTIONS CONTAINED THEREIN WILL BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE OR ERROR-FREE, THAT DEFECTS, IF ANY, WILL BE CORRECTED, OR THAT THIS SITE AND/OR THE SERVER THAT MAKES THE SAME AVAILABLE ARE FREE OF VIRUSES, CLOCKS, TIMERS, COUNTERS, WORMS, SOFTWARE LOCKS, DROP DEAD DEVICES, TROJAN-HORSES, ROUTINGS, TRAP DOORS, TIME BOMBS OR ANY OTHER HARMFUL CODES, INSTRUCTIONS, PROGRAMS OR COMPONENTS.</p>
        <p>15.2  YOU ACKNOWLEDGE THAT THE ENTIRE RISK ARISING OUT OF THE USE OR PERFORMANCE OF THE SITE AND/OR THE SERVICES REMAINS WITH YOU TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.</p>
        <p>15.3  JiMAT HAS NO CONTROL OVER AND, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, DOES NOT GUARANTEE OR ACCEPT ANY RESPONSIBILITY FOR: (A) THE FITNESS FOR PURPOSE, EXISTENCE, QUALITY, SAFETY OR LEGALITY OF ITEMS AVAILABLE VIA THE SERVICES; OR (B) THE ABILITY OF SELLERS TO SELL ITEMS OR OF BUYERS TO PAY FOR ITEMS.  IF THERE IS A DISPUTE INVOLVING ONE OR MORE USERS, SUCH USERS AGREE TO RESOLVE SUCH DISPUTE BETWEEN THEMSELVES DIRECTLY AND, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, RELEASE JiMAT AND ITS AFFILIATES FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES ARISING OUT OF OR IN CONNECTION WITH ANY SUCH DISPUTE.</p>
        <p><strong>16.  EXCLUSIONS AND LIMITATIONS OF LIABILITY</strong></p>
        <p>16.1  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL JiMAT BE LIABLE WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE (WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY), OR OTHER CAUSE OF ACTION AT LAW, IN EQUITY, BY STATUTE OR OTHERWISE, FOR:</p>
        <p>(i)  (A) LOSS OF USE; (B) LOSS OF PROFITS; © LOSS OF REVENUES; (D) LOSS OF DATA; (E) LOSS OF GOOD WILL; OR (F) FAILURE TO REALISE ANTICIPATED SAVINGS, IN EACH CASE WHETHER DIRECT OR INDIRECT; OR</p>
        <p>(ii) ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE USE OR INABILITY TO USE THIS SITE OR THE SERVICES, INCLUDING, WITHOUT LIMITATION, ANY DAMAGES RESULTING THEREFROM, EVEN IF JiMAT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
        <p>16.2 YOU ACKNOWLEDGE AND AGREE THAT YOUR ONLY RIGHT WITH RESPECT TO ANY PROBLEMS OR DISSATISFACTION WITH THE SERVICES IS TO REQUEST FOR TERMINATION OF YOUR ACCOUNT AND/OR DISCONTINUE ANY USE OF THE SERVICES.</p>
        <p>16.3 IF, NOTWITHSTANDING THE PREVIOUS SECTIONS, JiMAT IS FOUND BY A COURT OF COMPETENT JURISDICTION TO BE LIABLE (INCLUDING FOR GROSS NEGLIGENCE), THEN, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ITS LIABILITY TO YOU OR TO ANY THIRD PARTY IS LIMITED TO THE LESSER OF: (A) ANY AMOUNTS DUE AND PAYABLE TO; AND (A) RM 100 (ONE HUNDRED MALAYSIAN RINGGIT).</p>
        <p>16.4 NOTHING IN THESE TERMS OF SERVICE SHALL LIMIT OR EXCLUDE ANY LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY JiMAT’S NEGLIGENCE, FOR FRAUD OR FOR ANY OTHER LIABILITY ON THE PART OF JiMAT THAT CANNOT BE LAWFULLY LIMITED AND/OR EXCLUDED.</p>
        <p><strong>17.  LINKS TO THIRD PARTY SITES</strong></p>
        <p>Third party links provided throughout the Site will let you leave this Site. These links are provided as a courtesy only, and the sites they link to are not under the control of JiMAT in any manner whatsoever and you therefore access them at your own risk. JiMAT is in no manner responsible for the contents of any such linked site or any link contained within a linked site, including any changes or updates to such sites. JiMAT is providing these links merely as a convenience, and the inclusion of any link does not in any way imply or express affiliation, endorsement or sponsorship by JiMAT of any linked site and/or any of its content therein.</p>
        <p><strong>18. YOUR CONTRIBUTIONS TO THE SERVICES</strong></p>
        <p>18.1  By submitting Content for inclusion on the Services, you represent and warrant that you have all necessary rights and/or permissions to grant the licenses below to JiMAT. You further acknowledge and agree that you are solely responsible for anything you post or otherwise make available on or through the Services, including, without limitation, the accuracy, reliability, nature, rights clearance, compliance with law and legal restrictions associated with any Content contribution. You hereby grant JiMAT and its successors a perpetual, irrevocable, worldwide, non-exclusive, royalty-free, sub-licensable and transferable license to use, copy, distribute, republish, transmit, modify, adapt, create derivative works of, publicly display, and publicly perform such Content contribution on, through or in connection with the Services in any media formats and through any media channels, including, without limitation, for promoting and redistributing part of the Services (and its derivative works) without need of attribution and you agree to waive any moral rights (and any similar rights in any part of the world) in that respect. You understand that your contribution may be transmitted over various networks and changed to conform and adapt to technical requirements.</p>
        <p>18.2  Any Content, material, information or idea you post on or through the Services, or otherwise transmit to JiMAT by any means (each, a “Submission”), is not considered confidential by JiMAT and may be disseminated or used by JiMAT without compensation or liability to you for any purpose whatsoever, including, but not limited to, developing, manufacturing and marketing products. By making a Submission to JiMAT, you acknowledge and agree that JiMAT and/or other third parties may independently develop software, applications, interfaces, products and modifications and enhancements of the same which are identical or similar in function, code or other characteristics to the ideas set out in your Submission. Accordingly, you hereby grant JiMAT and its successors a perpetual, irrevocable, worldwide, non-exclusive, royalty-free, sub-licensable and transferable license to develop the items identified above, and to use, copy, distribute, republish, transmit, modify, adapt, create derivative works of, publicly display, and publicly perform any Submission on, through or in connection with the Services in any media formats and through any media channels, including, without limitation, for promoting and redistributing part of the Services (and its derivative works). This provision does not apply to personal information that is subject to our privacy policy except to the extent that you make such personal information publicly available on or through the Services.</p>
        <p><strong>19.  THIRD PARTY CONTRIBUTIONS TO THE SERVICES AND EXTERNAL LINKS</strong></p>
        <p>19.1  Each contributor to the Services of data, text, images, sounds, video, software and other Content is solely responsible for the accuracy, reliability, nature, rights clearance, compliance with law and legal restrictions associated with their Content contribution. As such, JiMAT is not responsible to, and shall not, regularly monitor or check for the accuracy, reliability, nature, rights clearance, compliance with law and legal restrictions associated with any contribution of Content. You will not hold JiMAT responsible for any User’s actions or inactions, including, without limitation, things they post or otherwise make available via the Services.</p>
        <p>19.2  In addition, the Services may contain links to third party products, websites, services and offers. These third party links, products, websites and services are not owned or controlled by JiMAT. Rather, they are operated by, and are the property of, the respective third parties, and may be protected by applicable copyright or other intellectual property laws and treaties. JiMAT has not reviewed, and assumes no responsibility for the content, functionality, security, services, privacy policies, or other practices of these third parties. You are encouraged to read the terms and other policies published by such third parties on their websites or otherwise. By using the Services, you agree that JiMAT shall not be liable in any manner due to your use of, or inability to use, any website or widget. You further acknowledge and agree that JiMAT may disable your use of, or remove, any third party links, or applications on the Services to the extent they violate these Terms of Service.</p>
        <p><strong>20. YOUR REPRESENTATIONS AND WARRANTIES</strong></p>
        <p>You represent and warrant that:</p>
        <p>(a)  you possess the legal capacity (and in the case of a minor, valid parent or legal guardian consent), right and ability to enter into these Terms of Service and to comply with its terms; and</p>
        <p>(b)  you will use the Services for lawful purposes only and in accordance with these Terms of Service and all applicable laws, rules, codes, directives, guidelines, policies and regulations.</p>
        <p><strong>21. INDEMNITY</strong></p>
        <p>You agree to indemnify, defend and hold harmless JiMAT, and its shareholders, subsidiaries, affiliates, directors, officers, agents, co-branders or other partners, and employees (collectively, the “Indemnified Parties”) from and against any and all claims, actions, proceedings, and suits and all related liabilities, damages, settlements, penalties, fines, costs and expenses (including, without limitation, any other dispute resolution expenses) incurred by any Indemnified Party arising out of or relating to: (a) any transaction made on the Site, or any dispute in relation to such transaction (except where JiMAT or its Affiliates is the Seller in the transaction that the dispute relates to), (b) the hosting, operation, management and/or administration of the Services by or on behalf of JiMAT, © your violation or breach of any term of these Terms of Service or any policy or guidelines referenced herein, (d) your use or misuse of the Services, (e) your breach of any law or any rights of a third party, or (f) any Content uploaded by you.</p>
        <p><strong>22. SEVERABILITY</strong></p>
        <p>If any provision of these Terms of Service shall be deemed unlawful, void, or for any reason unenforceable under the law of any jurisdiction, then that provision shall be deemed severable from these terms and conditions and shall not affect the validity and enforceability of any remaining provisions in such jurisdiction nor the validity and enforceability of the provision in question under the law of any other jurisdiction.</p>
        <p><strong>23. GOVERNING LAW</strong></p>
        <p>These Terms of Service shall be governed by and construed in accordance with the laws of Malaysia without regard to its conflict of law rules. The United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act, to the extent applicable, are expressly disclaimed. Unless otherwise required by applicable laws, any dispute, controversy, claim or difference of any kind whatsoever shall arise out of or relating to these Terms of Service against or relating to JiMAT or any Indemnified Party under these Terms of Service shall be referred to and finally resolved by arbitration in Malaysia in accordance with the Arbitration Rules of the Asian International Arbitration Centre (“AIAC Rules”) for the time being in force, which rules are deemed to be incorporated by reference in this Section. There will be one (1) arbitrator and the language of the arbitration shall be English.</p>
        <p><strong>24.  GENERAL PROVISIONS</strong></p>
        <p>24.1 JiMAT reserves all rights not expressly granted herein.</p>
        <p>24.2 JiMAT may modify these Terms of Service at any time by posting the revised Terms of Service on this Site. Your continued use of this Site after such changes have been posted shall constitute your acceptance of such revised Terms of Service.</p>
        <p>24.3  You may not assign, sublicense or transfer any rights granted to you hereunder or subcontract any of your obligations.</p>
        <p>24.4  Nothing in these Terms of Service shall constitute a partnership, joint venture or principal-agent relationship between you and JiMAT, nor does it authorise you to incur any costs or liabilities on JiMAT’s behalf.</p>
        <p>24.5 The failure of JiMAT at any time or times to require performance of any provision hereof shall in no manner affect its right at a later time to enforce the same unless the same is waived in writing.</p>
        <p>24.6  These Terms of Service are solely for your and our benefit and are not for the benefit of any other person or entity, except for JiMAT’s affiliates and subsidiaries (and each of JiMAT’s and its affiliates’ and subsidiaries’ respective successors and assigns).</p>
        <p>24.7  The terms set forth in these Terms of Service and any agreements and policies included or referred to in these Terms of Service constitute the entire agreement and understanding of the parties with respect to the Services and the Site and supersede any previous agreement or understanding between the parties in relation to such subject matter. The parties also hereby exclude all implied terms in fact. In entering into the agreement formed by these Terms of Service, the parties have not relied on any statement, representation, warranty, understanding, undertaking, promise or assurance of any person other than as expressly set out in these Terms of Service. Each party irrevocably and unconditionally waives all claims, rights and remedies which but for this Section it might otherwise have had in relation to any of the foregoing. These Terms of Service may not be contradicted, explained or supplemented by evidence of any prior agreement, any contemporaneous oral agreement or any consistent additional terms.</p>
        <p>24.8  You agree to comply with all applicable laws, statutes, regulations and codes relating to anti-bribery and corruption including without limitation the UK Bribery Act, the US Foreign Corrupt Practices Act and the Malaysian Anti-Corruption Commission Act 2009 and confirm that you have and shall have in place all policies and procedures needed to ensure compliance with such requirements.</p>
        <p>24.9  If you have any questions or concerns about these Terms of Service or any issues raised in these Terms of Service or on the Site, please contacts us at: <a href="mailto:support@robugroup.com">support@robugroup.com</a>.</p>
        <p><strong>LEGAL NOTICES</strong>: Please send all legal notices to <a href="mailto:support@robugroup.com">support@robugroup.com</a> and Attention it to the “General Counsel”.</p>
        <p>I HAVE READ THIS AGREEMENT AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE AND ANY REVISION THE SAME HEREAFTER. BY CLICKING THE “SIGN UP” OR “CONNECT WITH FACEBOOK” BUTTON BELOW AND/OR USING THE SERVICES IN JIMAT, I UNDERSTAND THAT I AM CREATING A DIGITAL SIGNATURE, WHICH I INTEND TO HAVE THE SAME FORCE AND EFFECT AS IF I HAD SIGNED MY NAME MANUALLY.</p>
        <p>Last Updated: 1st October 2019</p>
      </div>
    </Layout >
  )
}

export default TnC;