import React from 'react';

const OutletIcon = () => (
  <path
    d="M5 0h30a5 5 0 015 5v30a5 5 0 01-5 5H5a5 5 0 01-5-5V5a5 5 0 015-5zm25.722 10H10.278v2.625h20.444V10zM32 23.125V20.5l-1.278-6.563H10.278L9 20.5v2.625h1.278V31h12.778v-7.875h5.11V31h2.556v-7.875H32zm-11.5 5.25h-7.667v-5.25H20.5v5.25z"
    fill="#99AAB3"
    fillRule="evenodd"
  />
);

const LocationIcon = () => (
  <>
    <path
      d="M507.607 4.395c-4.242-4.245-10.61-5.551-16.177-3.32l-482 192.798c-5.516 2.205-9.209 7.458-9.42 13.394-.211 5.936 3.101 11.438 8.444 14.029l190.067 92.182 92.182 190.068c2.514 5.184 7.764 8.454 13.493 8.454.178 0 .357-.003.536-.01 5.936-.211 11.188-3.904 13.394-9.419L510.928 20.573c2.228-5.571.922-11.935-3.321-16.178z"
      fill="#e2e8f0"
    />
    <path
      d="M507.607 4.395L198.522 313.477l92.182 190.068c2.514 5.184 7.764 8.454 13.493 8.454.178 0 .357-.003.536-.01 5.936-.211 11.188-3.904 13.394-9.419L510.928 20.573c2.228-5.571.922-11.935-3.321-16.178z"
      fill="#cbd5e0"
    />
  </>
);

const InfoIcon = () => (
  <>
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="16" x2="12" y2="12"></line>
    <line x1="12" y1="8" x2="12" y2="8"></line>
  </>
);

const TrashIcon = () => (
  <>
    <polyline points="3 6 5 6 21 6"></polyline>
    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
    <line x1="10" y1="11" x2="10" y2="17"></line>
    <line x1="14" y1="11" x2="14" y2="17"></line>
  </>
);

const CrossIcon = () => (
  <>
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="15" y1="9" x2="9" y2="15"></line>
    <line x1="9" y1="9" x2="15" y2="15"></line>
  </>
);

const TickIcon = () => <polyline points="20 6 9 17 4 12"></polyline>;

const BankIcon = () => (
  <path
    fill="#1D2128"
    fillRule="nonzero"
    d="M.563 8.48c-.01.06-.017.122-.02.183v1.263c.003.52.325.986.811 1.169v1.858c.002.622.49 1.133 1.11 1.163V24.85c-.62.03-1.108.541-1.11 1.163v1.853c-.487.188-.81.656-.81 1.18v1.235c0 .23.186.417.416.417h28.061c.23 0 .416-.187.416-.417v-1.236c0-.523-.323-.992-.81-1.18v-1.863a1.167 1.167 0 0 0-1.11-1.163V14.116c.62-.03 1.108-.541 1.11-1.163v-1.858c.486-.183.809-.648.81-1.169V8.672a1.218 1.218 0 0 0-.02-.184.416.416 0 0 0 .336-.76L15.184.049a.415.415 0 0 0-.39 0L.224 7.72a.418.418 0 0 0 .34.76zm1.623 3.338v-.643h4.799v.637l-4.799.006zm10.685 2.3V24.85c-.62.03-1.107.541-1.11 1.163v1.775h-3.95v-1.775a1.167 1.167 0 0 0-1.109-1.163V14.116c.62-.03 1.107-.541 1.11-1.163v-1.778h3.944v1.775c.001.623.49 1.136 1.11 1.166l.005.002zm-.277-2.3v-.643h4.799v.637l-4.8.006zm10.685 2.3V24.85c-.62.03-1.107.541-1.11 1.163v1.775h-3.95v-1.775a1.167 1.167 0 0 0-1.11-1.163V14.116c.62-.03 1.108-.541 1.11-1.163v-1.778h3.945v1.775c0 .623.49 1.136 1.11 1.166l.005.002zm-.277-2.3v-.643H27.8v.637l-4.8.006zM27.8 27.15v.637h-4.805v-.637h4.805zm-10.408 0v.637h-4.805v-.637h4.805zm-4.111-13.862h-.36a.333.333 0 0 1-.334-.333v-.304h4.8v.304a.333.333 0 0 1-.334.333h-3.772zm2.999.835v10.72h-2.583v-10.72h2.583zm-3 11.555h3.773c.184 0 .333.15.333.334v.303h-4.799v-.303c0-.184.15-.334.333-.334h.36zM6.98 27.151v.637h-4.8v-.637h4.8zm-4.8-14.187v-.312h4.8v.304a.333.333 0 0 1-.333.333H2.505a.333.333 0 0 1-.324-.336v.01zm3.69 1.168v10.713H3.29V14.124l2.58.008zm-3.69 11.89c0-.185.15-.334.333-.334h4.134c.184 0 .333.149.333.334v.303h-4.8v-.303zm26.425 3.04v.818H1.371v-.818a.43.43 0 0 1 .43-.431h26.374a.43.43 0 0 1 .43.423v.008zm-.81-3.04v.303h-4.799v-.303c0-.185.15-.334.333-.334h4.142c.177.004.32.147.324.325v.009zm-3.69-1.169v-10.73h2.583v10.722l-2.582.008zm3.69-11.89a.333.333 0 0 1-.333.335h-4.13a.333.333 0 0 1-.333-.334v-.312h4.799l-.003.312zM14.988.884l12.391 6.531H2.597L14.987.883zM1.37 8.672c0-.23.186-.418.416-.418h26.402c.23 0 .416.187.416.418v1.263c0 .23-.186.417-.416.417H1.787a.417.417 0 0 1-.416-.417V8.672z"
  />
);

const CashIcon = () => (
  <path
    fill="#000"
    fillRule="nonzero"
    d="M29.828.171A.587.587 0 0 0 29.414 0H15a.585.585 0 0 0-.586.586v1.672L8.86 3.743a.585.585 0 0 0-.415.717l2.88 10.722-2.491 2.485a1.758 1.758 0 0 0-1.451-.766H1.758c-.97 0-1.758.787-1.758 1.755v9.532c0 .967.789 1.754 1.758 1.754h5.625a1.76 1.76 0 0 0 1.706-1.332l.697.606a2.956 2.956 0 0 0 1.94.726h17.688a.585.585 0 0 0 .586-.584V.585a.584.584 0 0 0-.172-.414zM7.968 28.188a.586.586 0 0 1-.585.585H1.758a.586.586 0 0 1-.586-.585v-9.532c0-.323.263-.585.586-.585h2.226v6.737a.585.585 0 0 0 1.172 0V18.07h2.227c.323 0 .586.262.586.585v9.532zM9.73 4.722l4.685-1.253v2.167l-.393.105a.586.586 0 0 0-.414.716 1.576 1.576 0 0 1-1.116 1.929.586.586 0 0 0-.414.717l2.284 8.441h-1.176l-.606-2.366a.583.583 0 0 0-.098-.205L9.729 4.722zm4.685 3.596v4.934l-1.069-3.95c.447-.23.812-.573 1.069-.984zm8.418 20.455H11.726c-.43 0-.846-.156-1.17-.438L9.14 27.103v-8.088l2.558-2.553.464 1.812c.066.258.3.44.567.44h11.52a.82.82 0 0 1 .82.819.82.82 0 0 1-.82.819h-5.902a.585.585 0 1 0 0 1.17h7.736a.82.82 0 0 1 .82.818.82.82 0 0 1-.82.819h-7.736a.585.585 0 1 0 0 1.17h6.483a.82.82 0 0 1 .82.818.82.82 0 0 1-.82.819h-6.483a.585.585 0 1 0 0 1.17h4.484a.82.82 0 0 1 .82.818.82.82 0 0 1-.82.819zm5.996 0h-4.181a1.973 1.973 0 0 0 0-1.638h.184a1.993 1.993 0 0 0 1.992-1.988c0-.32-.077-.622-.212-.89a1.992 1.992 0 0 0 1.466-1.917c0-.444-.147-.854-.394-1.185v-7.412a.585.585 0 0 0-1.172 0v6.655a1.996 1.996 0 0 0-.427-.046h-.019c.114-.25.178-.527.178-.82a1.992 1.992 0 0 0-1.993-1.987h-.942c1-.428 1.703-1.42 1.703-2.574a2.805 2.805 0 0 0-5.608 0c0 1.153.702 2.146 1.702 2.574h-3.203V6.425a2.756 2.756 0 0 0 2.101-2.097h4.408a2.756 2.756 0 0 0 2.1 2.097v1.818a.585.585 0 0 0 1.172 0v-2.34a.585.585 0 0 0-.586-.585c-.87 0-1.577-.706-1.577-1.575a.585.585 0 0 0-.586-.584H19.48a.585.585 0 0 0-.586.584c0 .869-.708 1.575-1.578 1.575a.585.585 0 0 0-.586.585v11.642h-1.144V1.17h13.242v27.603zM22.207 16.6c-.9 0-1.632-.73-1.632-1.629a1.633 1.633 0 0 1 3.264 0c0 .898-.732 1.63-1.632 1.63z"
  />
);

const CardIcon = () => (
  <g fill="#1A1A1A" fill-rule="nonzero">
    <path d="M24.987 5.294V2.5A2.502 2.502 0 0 0 22.488 0H2.498A2.502 2.502 0 0 0 0 2.499v10.994a2.494 2.494 0 0 0 2.24 2.473l1.88 4.876a2.5 2.5 0 0 0 3.23 1.433l11.73-4.518a7.224 7.224 0 0 0 .622 6.414l.287.459v4.854a.5.5 0 0 0 .5.5h8.995a.5.5 0 0 0 .5-.5V12.99a6.54 6.54 0 0 0-1.915-4.607l-3.082-3.088zm0 2.989l1.516 3.937a1.5 1.5 0 0 1-.86 1.937l-.867.335a2.5 2.5 0 0 0 .21-1v-5.21zM.999 13.493V2.499a1.5 1.5 0 0 1 1.5-1.5h19.989a1.5 1.5 0 0 1 1.5 1.5v3.176h-.005l.004.011v7.807a1.517 1.517 0 0 1-.034.259l-4.577-4.578a2.666 2.666 0 0 0-3.848 3.687l1.951 2.131H2.5a1.5 1.5 0 0 1-1.5-1.5zm6.403 2.498L3.848 17.36l-.526-1.369h4.08zm-.412 5.351a1.5 1.5 0 0 1-1.938-.86l-.843-2.192 5.965-2.299v-.003h8.221l.65.709L6.99 21.342zm21.995 7.643h-7.996v-3.998h7.996v3.998zm0-4.998h-8.219l-.216-.346a6.232 6.232 0 0 1-.127-6.4.5.5 0 0 0-.065-.586l-4.096-4.468a1.666 1.666 0 0 1 2.407-2.305l7.963 7.962.707-.707-1.85-1.849.514-.2a2.499 2.499 0 0 0 1.432-3.228l-1.691-4.392 1.619 1.622a5.534 5.534 0 0 1 1.622 3.899v10.998z" />
    <path d="M5.697 6.996a1.3 1.3 0 0 0 1.3-1.299V3.298a1.3 1.3 0 0 0-1.3-1.3H3.298a1.3 1.3 0 0 0-1.3 1.3v2.399a1.3 1.3 0 0 0 1.3 1.3h2.399zM2.998 5.697v-.7h1v-1h-1v-.699a.3.3 0 0 1 .3-.3h2.399a.3.3 0 0 1 .3.3v.7h-1v1h1v.699a.3.3 0 0 1-.3.3H3.298a.3.3 0 0 1-.3-.3zM2.499 8.495h1.999v1h-2zM2.499 11.494h1.999v1h-2zM11.494 11.494h1.999v1h-2zM5.497 8.495h1.999v1H5.497zM8.495 8.495h2v1h-2zM11.494 8.495h1.999v1h-2zM20.989 2.499h1v1.499h-1zM18.99 2.499h1v1.499h-1zM16.99 2.499h1v1.499h-1zM14.992 2.499h1v1.499h-1zM21.988 25.986h1v1h-1z" />
  </g>
);

const UserIcon = () => (
  <>
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </>
);

const HomeIcon = () => (
  <>
    <path d="M20 9v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9" />
    <path d="M9 22V12h6v10M2 10.6L12 2l10 8.6" />
  </>
);

const ShoppingBagIcon = () => (
  <>
    <circle cx="10" cy="20.5" r="1" />
    <circle cx="18" cy="20.5" r="1" />
    <path d="M2.5 2.5h3l2.7 12.4a2 2 0 0 0 2 1.6h7.7a2 2 0 0 0 2-1.6l1.6-8.4H7.1" />
  </>
);

const SearchIcon = () => (
  <>
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </>
);

const ChevronDownIcon = () => <path d="M6 9l6 6 6-6" />;

const ChevronLeftIcon = () => <path d="M15 18l-6-6 6-6" />;

const ChevronRightIcon = () => <path d="M9 18l6-6-6-6" />;

const FacebookIcon = () => (
  <path d="M24 12.07C24 5.41 18.63 0 12 0S0 5.4 0 12.07C0 18.1 4.39 23.1 10.13 24v-8.44H7.08v-3.49h3.04V9.41c0-3.02 1.8-4.7 4.54-4.7 1.31 0 2.68.24 2.68.24v2.97h-1.5c-1.5 0-1.96.93-1.96 1.89v2.26h3.32l-.53 3.5h-2.8V24C19.62 23.1 24 18.1 24 12.07" />
);

const InstagramIcon = () => (
  <path d="M16.98 0a6.9 6.9 0 0 1 5.08 1.98A6.94 6.94 0 0 1 24 7.02v9.96c0 2.08-.68 3.87-1.98 5.13A7.14 7.14 0 0 1 16.94 24H7.06a7.06 7.06 0 0 1-5.03-1.89A6.96 6.96 0 0 1 0 16.94V7.02C0 2.8 2.8 0 7.02 0h9.96zm.05 2.23H7.06c-1.45 0-2.7.43-3.53 1.25a4.82 4.82 0 0 0-1.3 3.54v9.92c0 1.5.43 2.7 1.3 3.58a5 5 0 0 0 3.53 1.25h9.88a5 5 0 0 0 3.53-1.25 4.73 4.73 0 0 0 1.4-3.54V7.02a5 5 0 0 0-1.3-3.49 4.82 4.82 0 0 0-3.54-1.3zM12 5.76c3.39 0 6.2 2.8 6.2 6.2a6.2 6.2 0 0 1-12.4 0 6.2 6.2 0 0 1 6.2-6.2zm0 2.22a3.99 3.99 0 0 0-3.97 3.97A3.99 3.99 0 0 0 12 15.92a3.99 3.99 0 0 0 3.97-3.97A3.99 3.99 0 0 0 12 7.98zm6.44-3.77a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8z" />
);

const TwitterIcon = () => (
  <path d="M24 4.37a9.6 9.6 0 0 1-2.83.8 5.04 5.04 0 0 0 2.17-2.8c-.95.58-2 1-3.13 1.22A4.86 4.86 0 0 0 16.61 2a4.99 4.99 0 0 0-4.79 6.2A13.87 13.87 0 0 1 1.67 2.92 5.12 5.12 0 0 0 3.2 9.67a4.82 4.82 0 0 1-2.23-.64v.07c0 2.44 1.7 4.48 3.95 4.95a4.84 4.84 0 0 1-2.22.08c.63 2.01 2.45 3.47 4.6 3.51A9.72 9.72 0 0 1 0 19.74 13.68 13.68 0 0 0 7.55 22c9.06 0 14-7.7 14-14.37v-.65c.96-.71 1.79-1.6 2.45-2.61z" />
);

const HeartIcon = () => (
  <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
);

const PaperIcon = () => (
  <>
    <path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
    <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
  </>
);

const StarIcon = () => (
  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
);

const EditIcon = () => (
  <>
    <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
    <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
  </>
);

const FileIcon = () => (
  <>
    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
  </>
);

const LoadingIcon = () => {
  return (
    <>
      <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.2" />
    </>
  );
};

const Icon = ({
  type,
  fill = 'none',
  stroke = '#99aab3',
  size = 'medium',
  viewbox = '24',
}) => {
  const components = {
    outlet: OutletIcon,
    trash: TrashIcon,
    cross: CrossIcon,
    tick: TickIcon,
    bank: BankIcon,
    cash: CashIcon,
    card: CardIcon,
    user: UserIcon,
    home: HomeIcon,
    info: InfoIcon,
    'shopping-bag': ShoppingBagIcon,
    search: SearchIcon,
    'chevron-down': ChevronDownIcon,
    'chevron-left': ChevronLeftIcon,
    'chevron-right': ChevronRightIcon,
    facebook: FacebookIcon,
    instagram: InstagramIcon,
    twitter: TwitterIcon,
    heart: HeartIcon,
    paper: PaperIcon,
    star: StarIcon,
    edit: EditIcon,
    file: FileIcon,
    loading: LoadingIcon,
    location: LocationIcon,
  };

  const Component = components[type] || '';

  // size small, medium, big
  const length = (() => {
    switch (size) {
      case 'small':
        return '20';
      case 'medium':
        return '24';
      case 'big':
        return '30';
      case 'large':
        return '40';
      default:
        return '30';
    }
  })();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={length}
      height={length}
      viewBox={`0 0 ${viewbox} ${viewbox}`}
      fill={fill}
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="inline-block"
    >
      <Component />
    </svg>
  );
};

export default Icon;
